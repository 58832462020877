export const minimalTimezoneSet = [
    {
        offset: '-11:00',
        offsetInMinute: -660,
        label: '(GMT-11:00) Pago Pago',
        tzCode: 'Pacific/Pago_Pago',
    },
    {
        offset: '-10:00',
        offsetInMinute: -600,
        label: '(GMT-10:00) Hawaii Time',
        tzCode: 'Pacific/Honolulu',
    },
    {
        offset: '-10:00',
        offsetInMinute: -600,
        label: '(GMT-10:00) Tahiti',
        tzCode: 'Pacific/Tahiti',
    },
    {
        offset: '-09:00',
        offsetInMinute: -540,
        label: '(GMT-09:00) Alaska Time',
        tzCode: 'America/Anchorage',
    },
    {
        offset: '-08:00',
        offsetInMinute: -480,
        label: '(GMT-08:00) Pacific Time',
        tzCode: 'America/Los_Angeles',
    },
    {
        offset: '-07:00',
        offsetInMinute: -420,
        label: '(GMT-07:00) Mountain Time',
        tzCode: 'America/Denver',
    },
    {
        offset: '-06:00',
        offsetInMinute: -360,
        label: '(GMT-06:00) Central Time',
        tzCode: 'America/Chicago',
    },
    {
        offset: '-05:00',
        offsetInMinute: -300,
        label: '(GMT-05:00) Eastern Time',
        tzCode: 'America/New_York',
    },
    {
        offset: '-04:00',
        offsetInMinute: -240,
        label: '(GMT-04:00) Atlantic Time - Halifax',
        tzCode: 'America/Halifax',
    },
    {
        offset: '-03:00',
        offsetInMinute: -180,
        label: '(GMT-03:00) Buenos Aires',
        tzCode: 'America/Argentina/Buenos_Aires',
    },
    {
        offset: '-02:00',
        offsetInMinute: -120,
        label: '(GMT-02:00) Sao Paulo',
        tzCode: 'America/Sao_Paulo',
    },
    {
        offset: '-01:00',
        offsetInMinute: -60,
        label: '(GMT-01:00) Azores',
        tzCode: 'Atlantic/Azores',
    },
    { offset: '+00:00', offsetInMinute: 0, label: '(GMT+00:00) UTC', tzCode: 'UTC' },
    { offset: '+01:00', offsetInMinute: 60, label: '(GMT+01:00) Berlin', tzCode: 'Europe/Berlin' },
    {
        offset: '+02:00',
        offsetInMinute: 120,
        label: '(GMT+02:00) Helsinki',
        tzCode: 'Europe/Helsinki',
    },
    {
        offset: '+03:00',
        offsetInMinute: 180,
        label: '(GMT+03:00) Istanbul',
        tzCode: 'Europe/Istanbul',
    },
    { offset: '+04:00', offsetInMinute: 240, label: '(GMT+04:00) Dubai', tzCode: 'Asia/Dubai' },
    { offset: '+04:30', offsetInMinute: 270, label: '(GMT+04:30) Kabul', tzCode: 'Asia/Kabul' },
    {
        offset: '+05:00',
        offsetInMinute: 300,
        label: '(GMT+05:00) Maldives',
        tzCode: 'Indian/Maldives',
    },
    {
        offset: '+05:30',
        offsetInMinute: 330,
        label: '(GMT+05:30) India Standard Time',
        tzCode: 'Asia/Calcutta',
    },
    {
        offset: '+05:45',
        offsetInMinute: 345,
        label: '(GMT+05:45) Kathmandu',
        tzCode: 'Asia/Kathmandu',
    },
    { offset: '+06:00', offsetInMinute: 360, label: '(GMT+06:00) Dhaka', tzCode: 'Asia/Dhaka' },
    { offset: '+06:30', offsetInMinute: 390, label: '(GMT+06:30) Cocos', tzCode: 'Indian/Cocos' },
    { offset: '+07:00', offsetInMinute: 420, label: '(GMT+07:00) Bangkok', tzCode: 'Asia/Bangkok' },
    {
        offset: '+08:00',
        offsetInMinute: 480,
        label: '(GMT+08:00) Hong Kong',
        tzCode: 'Asia/Hong_Kong',
    },
    {
        offset: '+08:30',
        offsetInMinute: 510,
        label: '(GMT+08:30) Pyongyang',
        tzCode: 'Asia/Pyongyang',
    },
    { offset: '+09:00', offsetInMinute: 540, label: '(GMT+09:00) Tokyo', tzCode: 'Asia/Tokyo' },
    {
        offset: '+09:30',
        offsetInMinute: 570,
        label: '(GMT+09:30) Central Time - Darwin',
        tzCode: 'Australia/Darwin',
    },
    {
        offset: '+10:00',
        offsetInMinute: 600,
        label: '(GMT+10:00) Eastern Time - Brisbane',
        tzCode: 'Australia/Brisbane',
    },
    {
        offset: '+10:30',
        offsetInMinute: 630,
        label: '(GMT+10:30) Central Time - Adelaide',
        tzCode: 'Australia/Adelaide',
    },
    {
        offset: '+11:00',
        offsetInMinute: 660,
        label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
        tzCode: 'Australia/Sydney',
    },
    { offset: '+12:00', offsetInMinute: 720, label: '(GMT+12:00) Nauru', tzCode: 'Pacific/Nauru' },
    {
        offset: '+13:00',
        offsetInMinute: 780,
        label: '(GMT+13:00) Auckland',
        tzCode: 'Pacific/Auckland',
    },
    {
        offset: '+14:00',
        offsetInMinute: 840,
        label: '(GMT+14:00) Kiritimati',
        tzCode: 'Pacific/Kiritimati',
    },
]

export const removeUndefinedFromObject = (object) => {
    const cloneObject = {}
    Object.keys(object).forEach((key) => {
        if (object[key]) cloneObject[key] = object[key]
    })
    return cloneObject
}

export const copyToClipboard = async (text, createToast) => {
    try {
        await navigator.clipboard.writeText(text);
        if (createToast) {
            createToast('Copied!', 'Copied to your clipboard!');
        }
    } catch (err) {
        console.error('Failed to copy text:', err);
    }
};

export const convertToTree = (menuList, parentId=null,checkedMenuIds=[]) => {
    const tree = [];
  
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].parent_id === parentId) {
        if(checkedMenuIds.includes(menuList[i].id)) {
            menuList[i].checked = true
        }
        const children = convertToTree(menuList, menuList[i].id,checkedMenuIds);
        if (children.length) {
          menuList[i].children = children;
        }
        tree.push(menuList[i]);
      }
    }
  
    return tree;
};

export const parseCustomDate = (date) => {
    if(!date)
        return "";

    const validDate = new Date(date);

    const day = validDate.getDate().toString().padStart(2, '0');
    const month = (validDate.getMonth() + 1).toString().padStart(2, '0');
    const year = validDate.getFullYear();

    const hours = validDate.getHours().toString().padStart(2, '0');
    const minutes = validDate.getMinutes().toString().padStart(2, '0');
    const seconds = validDate.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const formatSnakeToTitle = (input, trimSuffix = "") => {
    if(!input)
        return ""

    if (trimSuffix && input.endsWith(trimSuffix)) {
        input = input.slice(0, -trimSuffix.length);
    }
    
    return input
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, char => char.toUpperCase());
}
export const isValidUrl=(urlString)=>{
    try {
      new URL(urlString);
      const reg = /^(https?:\/\/)/
      // URL 有效
      if(reg.test(urlString)) {
        return true
      } else {
        return false
      } 
      
    } catch (error) {
      return false; // URL 无效
    }
  }
